@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.typewriter::after {
  content: '|';
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  from, to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.card-link {
  text-decoration: none;
}
.card-container {
  max-width: 320px; /* Adjust the width to make the cards smaller */
  max-height: 320px;
  min-height: 256px;
  min-width: 256px;
  transition: transform 0.3s ease;
}

.card-container:hover {
  transform: translateY(-10px); /* Adjust the value to your preference */
}

.card-image {
  width: 100%;
  height: auto;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card-content {
  padding: 1rem;
}